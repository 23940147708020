<template>
  <v-row>
    <v-col cols="6">
      <v-icon>{{ labelIcon }}</v-icon> {{ label }}
    </v-col>
    <v-col cols="6" class="text-right">
      <slot>
        <strong>
          <RealtimeDataItem v-if="isRealtime" :value="value" />
          <span v-else>{{ value }}</span>
        </strong>
      </slot>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "ExploreFleetCardDetailsItem",
  components: {
    RealtimeDataItem: () => import("@/components/RealtimeDataItem"),
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    labelIcon: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number],
      required: false,
    },
    isRealtime: {
      type: Boolean,
      required: false,
    },
  },
};
</script>
